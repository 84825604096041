
























































































import { SearchBuilder } from "@/builder";
import { DisplayBoolean } from "@/components/DisplayBoolean";
import { FormModal } from "@/components/UserManagement/Menu";
import { APagination } from "@/hooks";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  ONE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { ResponseListOfMenus } from "@/models/interface/user.interface";
import { menuService } from "@/services/menu.service";
import { SorterProps } from "@/types";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    DisplayBoolean,
    FormModal,
  },
})
export default class MenuList extends Vue {
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  search = "";
  loading = false;
  selectedId = "";

  pagination = {
    page: +this.$route?.query?.p || DEFAULT_PAGE,
    limit: +this.$route?.query?.l || DEFAULT_PAGE_SIZE,
    reset(): void {
      this.page = DEFAULT_PAGE;
      this.limit = DEFAULT_PAGE_SIZE;
    },
  };

  menus: ResponseListOfMenus = {
    currentPage: 0,
    data: [],
    totalElements: 0,
    totalPages: 0,
  };

  modal = {
    open: false,
    toggle(): void {
      this.open = true;
    },
    reset(): void {
      this.open = false;
    },
  };

  columns = [
    {
      title: this.$t("lbl_name"),
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 150,
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_description"),
      dataIndex: "description",
      key: "description",
      sorter: true,
      width: 200,
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_active"),
      dataIndex: "active",
      key: "active",
      width: 60,
      scopedSlots: { customRender: "boolean" },
    },
    {
      title: this.$t("lbl_action"),
      width: 60,
      scopedSlots: { customRender: "action" },
    },
  ];

  mounted(): void {
    const params = this.buildQueryParams();
    this.getListOfMenu(params);
  }

  onSearch(): void {
    this.pagination.reset();
    const params = this.buildQueryParams();
    this.getListOfMenu(params);
  }

  onChangeTable(pagination: APagination, _filter, sorts: SorterProps): void {
    const { current, pageSize } = pagination;
    const { columnKey, order } = sorts;

    this.pagination.page = current;

    if (pageSize !== this.pagination.limit) {
      this.pagination.page = DEFAULT_PAGE;
    }

    this.pagination.limit = pageSize;

    const params = this.buildQueryParams();

    if (order && order === "ascend") {
      params.sorts = `${columnKey}:asc`;
    } else if (order && order === "descend") {
      params.sorts = `${columnKey}:desc`;
    }

    this.getListOfMenu(params);
  }

  resetFilter(): void {
    this.pagination.reset();
    this.search = "";

    const params = this.buildQueryParams();
    this.getListOfMenu(params);
  }

  buildQueryParams(): RequestQueryParams {
    const params = new RequestQueryParams();
    const builder = new SearchBuilder();

    params.limit = this.pagination.limit;
    params.page = this.pagination.page - ONE;

    if (this.search) {
      params.search = builder
        .push(["name", this.search], {
          like: "both",
        })
        .or()
        .push(["description", this.search], {
          like: "both",
        })
        .build();
    }

    return params;
  }

  @Watch("pagination", { deep: true })
  updateQuerySearch(): void {
    this.$router.replace({
      name: "user.menu",
      query: {
        q: this.search,
        p: this.pagination.page.toString(),
        l: this.pagination.limit.toString(),
      },
    });
  }

  getListOfMenu(params: RequestQueryParams): void {
    this.loading = true;
    menuService
      .listMenus(params)
      .then(res => {
        this.menus = res;
      })
      .finally(() => (this.loading = false));
  }

  onUpdateClick(id: string): void {
    this.selectedId = id;
    this.modal.toggle();
  }

  //#region modal
  onModalClose(): void {
    this.modal.reset();
    this.selectedId = "";
  }

  onRequestFinish(): void {
    this.onSearch();
  }

  openModal(): void {
    this.modal.toggle();
  }
  //#endregion
}
