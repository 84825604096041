
















































































































import { SearchBuilder } from "@/builder";
import { debounce } from "@/helpers/debounce";
import { generateUUID } from "@/helpers/uuid";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { IPrivilege } from "@/models/interface/privilege.interface";
import {
  Privileges,
  RequestMenu
} from "@/models/interface/user.interface";
import { menuService } from "@/services/menu.service";
import { privilegeService } from "@/services/privilege.service";
import { LabelInValue } from "@/types";
import { FormModel } from "ant-design-vue";
import { Component, Prop, Ref, Watch } from "vue-property-decorator";

type Row = {
  rowId: string;
  privilege?: LabelInValue;
  description: string;
  options: Option<IPrivilege>[];
  loading: boolean;
};

type FormData = {
  name: string;
  description: string;
  privileges: Row[];
  active: boolean;
};

@Component({})
export default class FormModal extends MNotificationVue {
  @Prop({ type: String, default: undefined, required: false })
  id!: string | undefined;

  @Prop({ type: Boolean, default: false, required: false })
  visible!: boolean;

  @Ref("formModel")
  formModel!: FormModel;

  options: Option<IPrivilege>[] = [];
  loadingSearch = false;
  loading = false;

  selectedRowKeys: string[] = [];
  form: FormData = {
    name: "",
    description: "",
    privileges: [],
    active: false,
  };

  columns = [
    {
      title: this.$t("lbl_privilege"),
      dataIndex: "privilege",
      key: "menu",
      width: 220,
      scopedSlots: { customRender: "menu" },
    },
    {
      title: this.$t("lbl_description"),
      dataIndex: "description",
      key: "description",
    },
  ];

  formRules = {
    name: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    description: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
  };

  get isEdit(): boolean {
    return !!this.id;
  }

  get title(): string {
    if (this.isEdit) {
      return this.$t("lbl_edit_x", {
        x: this.$t("lbl_menu"),
      }).toString();
    }
    return this.$t("lbl_create_x", {
      x: this.$t("lbl_menu"),
    }).toString();
  }

  handleClose(): void {
    this.formModel.resetFields();
    this.form.privileges = [];
    this.$emit("close");
  }

  handleAddRow() {
    this.form.privileges.push({
      privilege: undefined,
      description: "-",
      rowId: generateUUID(),
      loading: false,
      options: [],
    });
  }

  showConfirmation() {
    if (this.selectedRowKeys.length > 0) {
      this.showConfirmationDeleteItems(() => this.handleDeleteRow());
    } else {
      this.showNotifError("notif_must_select_items");
    }
  }

  handleDeleteRow() {
    this.form.privileges = this.form.privileges.filter(data => {
      return !this.selectedRowKeys.includes(data.rowId);
    });
    this.selectedRowKeys = [];
  }

  onSelectChange(selectedRowKeys: string[]) {
    this.selectedRowKeys = selectedRowKeys;
  }

  @Watch("id")
  onChangeId(id?: string): void {
    if (!id) {
      return;
    }

    menuService.findMenuDetail(id).then((res) => {
      this.form = {
        name: res.name || "",
        active: res.active || false,
        description: res.description || "",
        privileges: res.privileges.map((p: Privileges) => ({
          rowId: p.id,
          privilege: {
            key: p.id,
            label: p.authority,
          },
          description: p.description,
          loading: false,
          options: [],
        })),
      };
    });
  }

  mapFormDataToRequestMenu(formData: FormData): RequestMenu {
    return {
      name: formData.name,
      description: formData.description,
      privilegeIds: formData.privileges.map(p => p.privilege?.key || ""),
      active: formData.active,
    };
  }

  create(): void {
    this.loading = true;
    menuService
      .createMenu(this.mapFormDataToRequestMenu(this.form))
      .then(() => {
        this.showNotifSuccess("notif_create_success");
        this.handleClose();
        this.$emit("onRequestFinish");
      })
      .finally(() => (this.loading = false));
  }

  update(): void {
    if (!this.id) {
      return;
    }

    this.loading = true;
    menuService
      .updateMenu(this.mapFormDataToRequestMenu(this.form), this.id)
      .then(res => {
        this.showNotifSuccess("notif_update_success");
        this.handleClose();
        this.$emit("onRequestFinish");
      })
      .finally(() => (this.loading = false));
  }

  submit(): void {
    this.formModel.validate(valid => {
      if (!valid) {
        this.showNotifWarning("notif_validation_error");
        return;
      }

      if (this.isEdit) {
        this.update();
      } else {
        this.create();
      }
    });
  }

  //# region select privilege
  onSearch(search: string, row: Row): void {
    debounce(async () => {
      const params = new RequestQueryParams();
      const builder = new SearchBuilder();
      builder.push(["active", "true"]);

      if (search) {
        builder
          .and()
          .push(["authority", search], { like: "both" })
          .or()
          .push(["description", search], { like: "both" });
      }

      params.search = builder.build();

      row.loading = true;
      try {
        row.options = await this.getListPrivilege(params);
      } finally {
        row.loading = false;
      }
    });
  }

  async getListPrivilege(
    params: RequestQueryParams = new RequestQueryParams()
  ): Promise<Option<IPrivilege>[]> {
    const response = await privilegeService.listPrivileges(params);
    return response.data.map(privilege => ({
      key: privilege.id,
      label: privilege.authority,
      value: privilege.id,
      meta: privilege,
      title: privilege.authority,
    }));
  }

  onChange(value: LabelInValue, row: Row): void {
    if (!value) {
      this.onSearch(value, row);
    }

    const fromLocalOptions = row.options.find(e => value.key === e.key)?.meta;
    const fromGlobalOptions = this.options.find(e => value.key === e.key)?.meta;
    const privilege = fromLocalOptions || fromGlobalOptions;

    if (!privilege) {
      return;
    }

    row.description = privilege.description;
  }

  findOption(value?: string | LabelInValue): string | IPrivilege | undefined {
    if (!value) {
      return;
    }

    if (typeof value === "string") {
      return this.options.find(e => value === e.value)?.value;
    } else {
      return this.options.find(e => value.key === e.key)?.meta;
    }
  }

  async created(): Promise<void> {
    this.loadingSearch = true;
    try {
      this.options = await this.getListPrivilege();
    } finally {
      this.loadingSearch = false;
    }
  }
  //# region select privilege
}
