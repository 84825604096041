var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        "data-testid": "menu-list-modal",
        width: "50%"
      },
      on: { cancel: _vm.handleClose },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "a-button",
                {
                  key: "back",
                  attrs: { type: "danger" },
                  on: { click: _vm.handleClose }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
              ),
              _vm.$can("update", "menu") || _vm.$can("create", "menu")
                ? _c(
                    "a-button",
                    {
                      key: "submit",
                      attrs: {
                        type: "primary",
                        loading: _vm.loading,
                        "html-type": "submit"
                      },
                      on: { click: _vm.submit }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "a-form-model",
        { ref: "formModel", attrs: { model: _vm.form, rules: _vm.formRules } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_name"), prop: "name" } },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: _vm.$t("common.insert-text", {
                            text: _vm.$t("lbl_name")
                          }),
                          "data-testid": "menu-list-name-input"
                        },
                        model: {
                          value: _vm.form.name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_description"),
                        prop: "description"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: _vm.$t("common.insert-text", {
                            text: _vm.$t("lbl_description")
                          }),
                          "data-testid": "menu-list-description-input"
                        },
                        model: {
                          value: _vm.form.description,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "description", $$v)
                          },
                          expression: "form.description"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_active") } },
                    [
                      _c("a-switch", {
                        attrs: {
                          checkedChildren: _vm.$t("lbl_active"),
                          unCheckedChildren: _vm.$t("lbl_inactive"),
                          "data-testid": "menu-list-active-switch"
                        },
                        model: {
                          value: _vm.form.active,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "active", $$v)
                          },
                          expression: "form.active"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    [
                      _c(
                        "a-row",
                        {
                          staticClass: "mb-2",
                          attrs: { justify: "space-between", type: "flex" }
                        },
                        [
                          _c(
                            "a-tag",
                            {
                              staticStyle: { "font-size": "13px" },
                              attrs: { color: "grey" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("lbl_selected")) +
                                  " : " +
                                  _vm._s(_vm.selectedRowKeys.length) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "a-tag",
                            {
                              staticStyle: { "font-size": "13px" },
                              attrs: { color: "grey" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("lbl_total_found")) +
                                  " : " +
                                  _vm._s(_vm.form.privileges.length) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("a-table", {
                        attrs: {
                          size: "small",
                          columns: _vm.columns,
                          "data-source": _vm.form.privileges,
                          "row-selection": {
                            selectedRowKeys: _vm.selectedRowKeys,
                            onChange: _vm.onSelectChange
                          },
                          pagination: false,
                          "row-key": "rowId",
                          "data-testid": "menu-list-privileges-table"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "menu",
                            fn: function(_, row) {
                              return [
                                _c("a-select", {
                                  staticClass: "w-100",
                                  attrs: {
                                    placeholder: _vm.$t("common.search-text", {
                                      text: _vm.$t("lbl_privilege")
                                    }),
                                    "allow-clear": "",
                                    "show-search": "",
                                    "label-in-value": "",
                                    "filter-option": false,
                                    loading: _vm.loadingSearch,
                                    options:
                                      row.options.length > 0
                                        ? row.options
                                        : _vm.options
                                  },
                                  on: {
                                    search: function($event) {
                                      return _vm.onSearch($event, row)
                                    },
                                    change: function($event) {
                                      return _vm.onChange($event, row)
                                    }
                                  },
                                  model: {
                                    value: row.privilege,
                                    callback: function($$v) {
                                      _vm.$set(row, "privilege", $$v)
                                    },
                                    expression: "row.privilege"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _c(
                        "template",
                        { slot: "actions" },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "dashed", icon: "plus" },
                              on: { click: _vm.handleAddRow }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
                          ),
                          _c(
                            "a-button",
                            {
                              attrs: { type: "dashed", icon: "delete" },
                              on: { click: _vm.showConfirmation }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("lbl_delete_row")) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }