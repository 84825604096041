var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { title: _vm.title, visible: _vm.visible, width: "50%" },
      on: { cancel: _vm.handleCancel },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleCancel } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")
                  ]),
                  _vm.$can("update", "company")
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "primary", loading: _vm.loading },
                          on: { click: _vm.validateForm }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "a-form-model",
        {
          ref: "formModel",
          attrs: { model: _vm.form, rules: _vm.formRules, layout: "vertical" }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_name"), prop: "name" } },
                    [
                      _c("a-input", {
                        attrs: { "allow-clear": "" },
                        model: {
                          value: _vm.form.name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_code"), prop: "code" } },
                    [
                      _c("a-input", {
                        attrs: { "allow-clear": "" },
                        model: {
                          value: _vm.form.code,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "code", $$v)
                          },
                          expression: "form.code"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: { label: _vm.$t("lbl_address"), prop: "address" },
                      scopedSlots: _vm._u([
                        {
                          key: "extra",
                          fn: function() {
                            return [
                              _c("character-length", {
                                attrs: { value: _vm.form.address }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c("a-textarea", {
                        model: {
                          value: _vm.form.address,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "address", $$v)
                          },
                          expression: "form.address"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_tax_number"),
                        prop: "taxNumber"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: { "allow-clear": "" },
                        on: {
                          blur: function() {
                            return _vm.formatTax("format")
                          },
                          focus: function() {
                            return _vm.formatTax("reverse")
                          }
                        },
                        model: {
                          value: _vm.form.taxNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "taxNumber", $$v)
                          },
                          expression: "form.taxNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_idtku"), prop: "idtku" } },
                    [
                      _c("a-input", {
                        attrs: { "allow-clear": "" },
                        model: {
                          value: _vm.form.idtku,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "idtku", $$v)
                          },
                          expression: "form.idtku"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_phone"), prop: "phone" } },
                    [
                      _c("a-input", {
                        attrs: { "allow-clear": "" },
                        model: {
                          value: _vm.form.phone,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "phone", $$v)
                          },
                          expression: "form.phone"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_email"), prop: "email" } },
                    [
                      _c("a-input", {
                        attrs: { "allow-clear": "", type: "email" },
                        model: {
                          value: _vm.form.email,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: { label: _vm.$t("lbl_website"), prop: "company" }
                    },
                    [
                      _c("a-input", {
                        attrs: { "allow-clear": "" },
                        model: {
                          value: _vm.form.website,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "website", $$v)
                          },
                          expression: "form.website"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_fax"), prop: "fax" } },
                    [
                      _c("a-input", {
                        attrs: { "allow-clear": "" },
                        model: {
                          value: _vm.form.fax,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "fax", $$v)
                          },
                          expression: "form.fax"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_country_code"),
                        prop: "countryId"
                      }
                    },
                    [
                      _c("SelectCountryCode", {
                        attrs: { "label-in-value": "" },
                        model: {
                          value: _vm.form.countryCode,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "countryCode", $$v)
                          },
                          expression: "form.countryCode"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_logo"), prop: "logo" } },
                    [
                      !!_vm.company.logo && !_vm.allowEditImage
                        ? [
                            _c(
                              "a-space",
                              { attrs: { direction: "horizontal" } },
                              [
                                _c("figure", [
                                  _c("img", {
                                    staticClass: "img",
                                    attrs: {
                                      src: _vm.company.logo,
                                      alt: "logo"
                                    }
                                  })
                                ]),
                                _c("a-button", {
                                  attrs: { type: "primary", icon: "edit" },
                                  on: { click: _vm.editImage }
                                })
                              ],
                              1
                            )
                          ]
                        : [
                            _c("ImageUploader", {
                              attrs: { module: "company" },
                              on: { onSuccess: _vm.setLogo }
                            })
                          ]
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }