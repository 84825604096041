import { Api } from "@/models/class/api.class";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { ResponseListPrivilege } from "@/models/interface/privilege.interface";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class PrivilegeService extends HttpClient {
  constructor() {
    super();
  }

  listPrivileges(
    params: RequestQueryParamsModel
  ): Promise<ResponseListPrivilege> {
    return this.get<ResponseListPrivilege>(Api.Privilege, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const privilegeService = new PrivilegeService();
