var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.$t("lbl_title_list_menu") } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "a-space",
                            [
                              _c("a-input-search", {
                                attrs: {
                                  loading: _vm.loading,
                                  "enter-button": "",
                                  placeholder: _vm.$t(
                                    "lbl_input_search_placeholder"
                                  ),
                                  "allow-clear": "",
                                  "data-testid": "listmenu-search"
                                },
                                on: { search: _vm.onSearch },
                                model: {
                                  value: _vm.search,
                                  callback: function($$v) {
                                    _vm.search = $$v
                                  },
                                  expression: "search"
                                }
                              }),
                              _c("a-button", {
                                attrs: {
                                  type: "primary",
                                  icon: "reload",
                                  "data-testid": "listmenu-reload"
                                },
                                on: { click: _vm.resetFilter }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.$can("create", "menu")
                        ? _c(
                            "a-col",
                            {
                              attrs: {
                                span: 12,
                                align: "end",
                                "data-testid": "listmenu-btn-create-container"
                              }
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.openModal }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_create_new")) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("a-table", {
                    attrs: {
                      "row-key": "id",
                      dataSource: _vm.menus.data,
                      columns: _vm.columns,
                      loading: _vm.loading,
                      pagination: {
                        showTotal: function() {
                          return _vm.$t("lbl_total_items", {
                            total: _vm.menus.totalElements
                          })
                        },
                        total: _vm.menus.totalElements || 0,
                        showSizeChanger: true,
                        pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
                        current: _vm.pagination.page,
                        defaultPageSize: _vm.pagination.limit,
                        pageSize: _vm.pagination.limit
                      },
                      scroll: { x: 1000 },
                      "row-class-name": function(_record, index) {
                        return index % 2 ? "bg-white" : "bg-gray-light"
                      },
                      "data-testid": "listmenu-table"
                    },
                    on: { change: _vm.onChangeTable },
                    scopedSlots: _vm._u([
                      {
                        key: "boolean",
                        fn: function(_, row) {
                          return [
                            _c("DisplayBoolean", {
                              attrs: { value: row.active }
                            })
                          ]
                        }
                      },
                      {
                        key: "action",
                        fn: function(_, row) {
                          return [
                            _vm.$can("update", "menu")
                              ? _c(
                                  "a-button",
                                  {
                                    attrs: { icon: "edit", type: "link" },
                                    on: {
                                      click: function($event) {
                                        return _vm.onUpdateClick(row.id)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("lbl_update")) + " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("FormModal", {
        attrs: { visible: _vm.modal.open, id: _vm.selectedId },
        on: { close: _vm.onModalClose, onRequestFinish: _vm.onRequestFinish }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }